let skin = $('#skin')
document.addEventListener("turbolinks:load", () => {
    $('[data-toggle = "tooltip"]').tooltip()
    $('.datepicker').datepicker({
        format: 'yyyy-mm-dd'
    });

})
$(document).ready(function () {
    $('.ckeditor').each(function () {
        return CKEDITOR.replace($(this).attr('id'));
    });

})

skin.on('change', function () {
    if (skin.val() === 'skin-3') {
        $('body').addClass('skin-3');
    }
    if (skin.val() === 'skin-0') {
        $('body').removeClass('skin-3');
    }
});


$(document).on('ajax:complete', function (event) {
    let request = event.detail[0]
    let msg = request.getResponseHeader("X-Message");
    let alert_type = 'alert-success';
    if (request.getResponseHeader("X-Message-Type").indexOf("error") !== -1) {
        alert_type = 'alert-danger';
    }
    if (request.getResponseHeader("X-Message-Type").indexOf("keep") !== 0) {
        if (msg) {
            $("#flash_hook").replaceWith("<div id='flash_hook'> <p>&nbsp;</p> <div class='row'> <div class='span10 offset1'> <div class='alert " + alert_type + "'> <button type='button' class='close' data-dismiss='alert'>&times;</button>" + msg + "</div> </div> </div> </div>");
        }
        if (!msg) {
            return $("#flash_hook").replaceWith("<div id='flash_hook'></div>");
        }
    }
});

//character counter

$('.count-text').keyup(function () {
    const group = $(this).closest('.input-group');
    const counter = group.find('.counter');
    const max_length = parseInt(counter.data("max")) - counter.text.length;
    const cur_count = max_length - $(this).val().length;
    if (cur_count <= 0) {
        counter.css('color', 'red');
    } else {
        counter.css('color', 'lightgray');
    }
    counter.text('Characters left: ' + String(cur_count));

});
$(document).ready(function () {
    let helpTip = $('.help-tip')
    helpTip.hover(function () {
        $(this).next('.job-aid').fadeToggle()
    })
})

$(document).ready(function () {
    $('.datepicker').datepicker({
        format: 'mm/dd/yy',
        minDate: '01/01/1900'

    });


    // Add body-small class if window less than 768px
    if ($(this).width() < 769) {
        $('body').addClass('mini-navbar body-small');
        SmoothlyMenu();
    } else {
        $('body').removeClass('mini-navbar body-small');
        SmoothlyMenu();
    }

    // MetsiMenu
    $('#side-menu').metisMenu();

    // Collapse ibox function
    $('.collapse-link').click(function () {
        let ibox = $(this).closest('div.ibox');
        let button = $(this).find('i');
        let content = ibox.find('div.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link').click(function () {
        let content = $(this).closest('div.ibox');
        content.remove();
    });

    // Fullscreen ibox function
    $('.fullscreen-link').click(function () {
        let ibox = $(this).closest('div.ibox');
        let button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });

    // Close menu in canvas mode
    $('.close-canvas-menu').click(function () {
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();
    });

    // Run menu of canvas
    $('body.canvas-menu .sidebar-collapse').slimScroll({
        height: '100%',
        railOpacity: 0.9
    });

    // Open close right sidebar
    $('.right-sidebar-toggle').click(function () {
        $('#right-sidebar').toggleClass('sidebar-open');
    });

    // Initialize slimscroll for right sidebar
    $('.sidebar-container').slimScroll({
        height: '100%',
        railOpacity: 0.4,
        wheelStep: 10
    });

    // Open close small chat
    $('.open-small-chat').click(function () {
        $(this).children().toggleClass('fa-comments').toggleClass('fa-remove');
        $('.small-chat-box').toggleClass('active');
    });

    // Initialize slimscroll for small chat
    $('.small-chat-box .content').slimScroll({
        height: '234px',
        railOpacity: 0.4
    });

    // Small todo handler
    $('.check-link').click(function () {
        let button = $(this).find('i');
        let label = $(this).next('span');
        button.toggleClass('fa-check-square').toggleClass('fa-square-o');
        label.toggleClass('todo-completed');
        return false;
    });

    // Minimalize menu
    $('.navbar-minimalize').click(function () {
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();

    });

    // Tooltips demo
    $('.tooltip-demo').tooltip({
        selector: "[data-toggle=tooltip]",
        container: "body"
    });

    // Move modal to body
    // Fix Bootstrap backdrop issu with animation.css
    $('.modal').appendTo("body");

    // Full height of sidebar
    function fix_height() {
        const pageWrapper = $('#page-wrapper')
        let heightWithoutNavbar = $("body > #wrapper").height() - 61;
        $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

        let navbarHeigh = $('nav.navbar-default').height();
        let wrapperHeigh = pageWrapper.height();

        if (navbarHeigh > wrapperHeigh) {
            pageWrapper.css("min-height", navbarHeigh + "px");
        }

        if (navbarHeigh < wrapperHeigh) {
            pageWrapper.css("min-height", $(window).height() + "px");
        }

        if ($('body').hasClass('fixed-nav')) {
            if (navbarHeigh > wrapperHeigh) {
                pageWrapper.css("min-height", navbarHeigh - 60 + "px");
            } else {
                pageWrapper.css("min-height", $(window).height() - 60 + "px");
            }
        }

    }

    fix_height();

    // Fixed Sidebar
    $(window).bind("load", function () {
        if ($("body").hasClass('fixed-sidebar')) {
            $('.sidebar-collapse').slimScroll({
                height: '100%',
                railOpacity: 0.9
            });
        }
    });

    // Move right sidebar top after scroll
    $(window).scroll(function () {
        if ($(window).scrollTop() > 0 && !$('body').hasClass('fixed-nav')) {
            $('#right-sidebar').addClass('sidebar-top');
        } else {
            $('#right-sidebar').removeClass('sidebar-top');
        }
    });

    $(window).bind("load resize scroll", function () {
        if (!$("body").hasClass('body-small')) {
            fix_height();
        }
    });


    // Add slimscroll to element
    $('.full-height-scroll').slimscroll({
        height: '100%'
    })
});


// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
    if ($(this).width() < 769) {
        $('body').addClass('mini-navbar body-small');
        SmoothlyMenu();
    } else {
        $('body').removeClass('mini-navbar body-small');
        SmoothlyMenu();
    }

});

// Local Storage functions
// Set proper body class and plugins based on user configuration
$(document).ready(function () {
    if (localStorageSupport) {

        let collapse = localStorage.getItem("collapse_menu");
        let fixedsidebar = localStorage.getItem("fixedsidebar");
        let fixednavbar = localStorage.getItem("fixednavbar");
        let boxedlayout = localStorage.getItem("boxedlayout");
        let fixedfooter = localStorage.getItem("fixedfooter");

        let body = $('body');

        if (fixedsidebar === 'on') {
            body.addClass('fixed-sidebar');
            $('.sidebar-collapse').slimScroll({
                height: '100%',
                railOpacity: 0.9
            });
        }

        if (collapse === 'on') {
            if (body.hasClass('fixed-sidebar')) {
                if (!body.hasClass('body-small')) {
                    body.addClass('mini-navbar');
                }
            } else {
                if (!body.hasClass('body-small')) {
                    body.addClass('mini-navbar');
                }

            }
        }

        if (fixednavbar === 'on') {
            $(".navbar-static-top").removeClass('navbar-static-top').addClass('navbar-fixed-top');
            body.addClass('fixed-nav');
        }

        if (boxedlayout === 'on') {
            body.addClass('boxed-layout');
        }

        if (fixedfooter === 'on') {
            $(".footer").addClass('fixed');
        }
    }
});

// check if browser support HTML5 local storage
function localStorageSupport() {
    return (('localStorage' in window) && window['localStorage'] !== null)
}


function SmoothlyMenu() {
    const body = $('body')
    if (!body.hasClass('mini-navbar') || body.hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 200);
    } else if (body.hasClass('fixed-sidebar')) {
        $('#side-menu').hide();
        setTimeout(
            function () {
                $('#side-menu').fadeIn(400);
            }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style');
    }

}






